<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 100%; height: 2.6rem;position: absolute;"
        src="../../../assets/news/news_pic.jpg"
        alt />
    </view>

    <!--面包屑-->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <el-breadcrumb separator="》"
                   style="
        width: auto;
        height: 0.15rem;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    >
      <el-breadcrumb-item :to="{ path: '/' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          首页
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/news' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          新闻资讯
        </text>

      </el-breadcrumb-item>

      <el-breadcrumb-item>
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #45A2FF
          "
        >
          产品资讯
        </text>

      </el-breadcrumb-item>
    </el-breadcrumb>

    <!--横线-->
    <view
      style="
        width: 14.4rem;
        height: 0.01rem;
        background: #CCCCCC;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    />

    <!--日期-->
    <text
      style="
          width: auto;
          height: auto;

          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 0.32rem;

          margin-top: 0.19rem;
          margin-left: 2.4rem;

      ">
      2021.08.16
    </text>

    <!--标题-->
    <text
      style="
          width: 14.34rem;
          height: auto;

          font-size: 0.32rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;

          margin-top: 0.46rem;
          margin-left: 2.4rem;
          text-align: center;

      ">
      创美智慧管道直饮水开启健康饮水新时代
    </text>

    <!-- 介绍文字   -->
    <text
      style="
        width: 14.34rem;
        height: auto;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgb(0, 0, 0);
        line-height: 0.62rem;
        margin-top: 0.73rem;
        margin-left: 2.4rem;


      ">
      &emsp;&emsp;创美管道直饮水，采用先进的反渗透及臭氧紫外线复合杀菌技术，不同水源采用不同水处理工艺，使水质达到国家标准GB19298-2014 《食品安全国家标准 包装饮用水》，通过食品卫生级不锈钢循环管道输送到每家每户，为用户提供安全可靠的直饮水。
    </text>


    <!-- 标题-->
    <view style="
       width: 14.34rem;
       height: auto;
       margin-top: 0.53rem;

       display: flex;
       justify-content: center;
       margin-left: 2.4rem;

    ">
      <text
        style="
          width: 3.9rem;
          height: auto;
          line-height: 0.63rem;
          font-size: 0.29rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgb(255, 255, 255);
          text-align: center;
          border-radius: 0.1rem;
          background: rgb(43, 79, 151);

        ">
        24小时畅饮健康鲜活水

      </text>
    </view>

    <!-- 文字   -->
    <text
      style="
        width: 14.1rem;
        height: auto;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgb(0, 0, 0);
        line-height: 0.52rem;
        margin-top: 0.5rem;
        margin-left: 2.4rem;
        border-radius: 0.1rem;
        border: 0.04rem solid rgb(51, 153, 255);
        padding: 0.1rem;
        text-align: center;

      ">
      通过多重过滤、杀菌、净化，有效去除水中的细菌、杂质、病毒、重金属离子，循环管网24小时杀菌消毒

    </text>


    <!-- 标题-->
    <view style="
       width: 14.34rem;
       height: auto;
       margin-top: 0.93rem;

       display: flex;
       justify-content: center;
       margin-left: 2.4rem;

    ">
      <text
        style="
          width: 3.07rem;
          height: auto;
          line-height: 0.63rem;
          font-size: 0.29rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgb(255, 255, 255);
          text-align: center;
          border-radius: 0.1rem;
          background: rgb(43, 79, 151);

        ">
        物联网技术应用

      </text>
    </view>


    <!-- 介绍文字   -->
    <text
      style="
          width: 14.34rem;
          height: auto;
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(0, 0, 0);
          line-height: 0.32rem;
          margin-top: 0.5rem;
          margin-left: 2.4rem;

      ">
      &emsp;&emsp;用户可在线查看水质、用水统计、掌上充值、预警服务等

    </text>

    <el-image
      :src="require('../../../assets/news/chanpin/detail-1/news_detail_cm_zys_1_1.png')"
      fit="contain"
      style="
          margin-top: 0.2rem;
          margin-left: 2.6rem;
          width: 14.3rem;
          "
      lazy
    />


    <!-- 标题-->
    <view style="
       width: 14.34rem;
       height: auto;
       margin-top: 0.63rem;

       display: flex;
       justify-content: center;
       margin-left: 2.4rem;

    ">
      <text
        style="
          width: 3.07rem;
          height: auto;
          line-height: 0.63rem;
          font-size: 0.29rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgb(255, 255, 255);
          text-align: center;
          border-radius: 0.1rem;
          background: rgb(43, 79, 151);

        ">
        安装入户

      </text>
    </view>

    <!-- 介绍文字   -->
    <text
      style="
        width: 14.34rem;
        height: auto;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgb(0, 0, 0);
        margin-top: 0.5rem;
        margin-left: 2.4rem;

      ">
      &emsp;&emsp;通过食品卫生级不锈钢循环管网输送到每家每户，让您在家中随时享用健康鲜活纯净水。

    </text>


    <el-image
      :src="require('../../../assets/news/chanpin/detail-1/news_detail_cm_zys_2.png')"
      fit="contain"
      style="
            margin-top: 0.6rem;
            margin-left: 3.8rem;
            width: 9.8rem;
          "
      lazy
    />


    <!-- 标题-->
    <view style="
       width: 14.34rem;
       height: auto;
       margin-top: 0.93rem;

       display: flex;
       justify-content: center;
       margin-left: 2.4rem;

    ">
      <text
        style="
          width: 3.07rem;
          height: auto;
          line-height: 0.63rem;
          font-size: 0.29rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgb(255, 255, 255);
          text-align: center;
          border-radius: 0.1rem;
          background: rgb(43, 79, 151);

        ">
        五大优势

      </text>
    </view>

    <el-image
      :src="require('../../../assets/news/chanpin/detail-1/news_detail_cm_zys_3.png')"
      fit="contain"
      style="
          margin-top: 0.4rem;
          margin-left: 3.8rem;
          width: 12rem;
          "
      lazy
    />


    <!-- 智慧云平台-->
    <!-- 标题-->
    <view style="
       width: 14.34rem;
       height: auto;
       margin-top: 0.93rem;

       display: flex;
       justify-content: center;
       margin-left: 2.4rem;

    ">
      <text
        style="
          width: 3.07rem;
          height: auto;
          line-height: 0.63rem;
          font-size: 0.29rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgb(255, 255, 255);
          text-align: center;
          border-radius: 0.1rem;
          background: rgb(43, 79, 151);

        ">
        智慧云平台

      </text>
    </view>

    <el-image
      :src="require('../../../assets/news/chanpin/detail-1/news_detail_cm_zys_4.png')"
      fit="contain"
      style="
        margin-top: 0.4rem;
        margin-left: 2.8rem;
        width: 13.8rem;
          "
      lazy
    />


    <!-- 系统工艺-->
    <!-- 标题-->
    <view style="
       width: 14.34rem;
       height: auto;
       margin-top: 0.93rem;

       display: flex;
       justify-content: center;
       margin-left: 2.4rem;

    ">
      <text
        style="
          width: 3.07rem;
          height: auto;
          line-height: 0.63rem;
          font-size: 0.29rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgb(255, 255, 255);
          text-align: center;
          border-radius: 0.1rem;
          background: rgb(43, 79, 151);

        ">
        系统工艺

      </text>
    </view>

    <el-image
      :src="require('../../../assets/news/chanpin/detail-1/news_detail_cm_zys_5.png')"
      fit="contain"
      style="
          margin-top: 0.4rem;
          margin-left: 2.8rem;
          width: 13.8rem;
          height: 8.0rem;
       "
      lazy
    />










  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "Chanpin_Detail_1",

    setup() {
      //const router = useRouter()


      return {}
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/

    /*隐藏滚动条*/
    overflow-x: hidden;
    /*overflow-y: auto;*/

    border-radius: 0;
  }

  /*通用标题*/
  .itemTitle{
    width: auto;
    height: 0.7rem;
    margin-left: 2.4rem;
    position: relative;

    &_img{
      width: 2.29rem;
      height: 0.7rem;

      position:absolute;
      top: 0;
      left: 0rem;

    }

    &_txt{
      width: auto;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      position: absolute;
      left: 0.82rem;
      top: 0.14rem;
    }

  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }


</style>
